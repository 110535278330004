.ContactPage {
  position: relative;
  top: 56px;

  &__Container {
    display: flex;
    justify-content: flex-end;
  }
}

.ContactPage .form-control {
  resize: none;
}

.ContactPage .form-label {
  text-align: start;
  width: 100%;
}

.ContactPage .form-label {
  text-align: start;
  width: 100%;
}

.ContactPage h1 {
  text-align: center;
}

.ContactPage .pt-5 {
  padding-bottom: 3rem !important;
}

.ContactPage .valid-feedback {
  text-align: start;
  width: 100%;
}

.ContactPage .invalid-feedback {
  text-align: start;
  width: 100%;
}
