@use "../../styles/partials/breakpoints" as *;

.HomePage {
  position: relative;
  top: 56px;

  &__subSection {
    margin: 1rem 0;
    padding: 1rem;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 10px;
    background-color: #6c757d;
    display: flex;
    color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5) !important;
    @include tablet {
      margin: 1rem 0.5rem;
      width: 90%;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.HomePage .col-lg-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include tablet {
    width: 30%;
  }
}

.HomePage .row {
  margin: 1rem;
  @include tablet {
    display: flex;
    justify-content: center;
  }
}

.HomePage .pt-5 {
  padding-bottom: 3rem !important;
}
